import { IBrilliantSwitchCard } from "../../interfaces/interfaces";
import { ReactComponent as Switch } from "../../img/Devices/Switch.svg";

import BrilliantDeviceCard from "./BrilliantDeviceCard";

const switchIcon = (isReconfiguring: boolean) => {
  return (
    <Switch
      title="Smart Dimmer Switch"
      className={
        isReconfiguring
          ? "brilliant-card-img-left brilliant-card-img-left-stroke disabled"
          : "brilliant-card-img-left brilliant-card-img-left-stroke"
      }
    />
  );
};

export interface BrilliantSwitchCardProps {
  data: IBrilliantSwitchCard;
}

const BrilliantSwitchCard: React.FC<BrilliantSwitchCardProps> = ({ data }) => {
  return (
    <BrilliantDeviceCard
      id={data.id}
      name={data.name}
      description="Smart Dimmer Switch"
      isReconfiguring={data.isReconfiguring}
      icon={switchIcon(data.isReconfiguring)}
    />
  );
};
export default BrilliantSwitchCard;
