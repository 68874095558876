import {
  EUnitDetailsFilter,
  IBrilliantDevices,
  IIntegrationsCard,
  IIntegrationsNameAndLink,
  IUnitDetailsDataHome,
} from "../../../interfaces/interfaces";
import BackupWifiSettingsCard from "../BackupWifiSettingsCard";
import InstallationSettingsCard from "../InstallationSettingsCard";
import ViewResidents from "../ViewResidents";
import IntegrationLink from "../../common/IntegrationLink";
import {
  BrilliantDevices,
  IntegrationDevices,
} from "../common/CommonUnitDetailsComponents";

export interface SmallBodyProps {
  home: IUnitDetailsDataHome;
  brilliantDevices: IBrilliantDevices;
  currentUnitDetailsFilter: EUnitDetailsFilter;
  propertyId: string;
  isInReconfigState: boolean;
  integrations: IIntegrationsCard[];
  setShowInviteTenantModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowBackupWifiNetworkModal: React.Dispatch<React.SetStateAction<boolean>>;
  openEditTenantModalWizard: () => void;
  hasIntegration: boolean;
  integrationNameLink: IIntegrationsNameAndLink;
  setInviteTenantCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setShowMoveOutModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCancelMoveInModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowReconfigurationModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowResetInstallationModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const SmallBody: React.FC<SmallBodyProps> = ({
  home,
  brilliantDevices,
  currentUnitDetailsFilter,
  propertyId,
  isInReconfigState,
  integrations,
  setShowInviteTenantModal,
  openEditTenantModalWizard,
  hasIntegration,
  setShowBackupWifiNetworkModal,
  integrationNameLink,
  setInviteTenantCurrentStep,
  setShowMoveOutModal,
  setShowCancelMoveInModal,
  setShowReconfigurationModal,
  setShowResetInstallationModal,
}) => {
  const showSmallContainerForResidentViewWithNoMargin =
    currentUnitDetailsFilter === EUnitDetailsFilter.residents && hasIntegration;
  return (
    <div
      className={`brilliant-container-small ${
        showSmallContainerForResidentViewWithNoMargin
          ? "brilliant-container-small-no-margins"
          : ""
      }`}
      data-testid="brilliant-devices-container"
    >
      {currentUnitDetailsFilter === EUnitDetailsFilter.brilliants && (
        <>
          {!home.isHubless && (
            <BrilliantDevices
              brilliantControls={brilliantDevices.installedBrilliantControls}
              brilliantSwitches={brilliantDevices.installedBrilliantSwitches}
              brilliantPlugs={brilliantDevices.installedBrilliantPlugs}
              isInReconfigState={isInReconfigState}
            />
          )}
          <div className="brilliant-container-name menu brilliant-container-small-padding">
            Installation Settings
          </div>
          <div className="brilliant-container-cards">
            <BackupWifiSettingsCard
              data={{
                networkName: home.wifiCredentials.networkName,
              }}
              openModalFn={() => {
                setShowBackupWifiNetworkModal(true);
              }}
            />
            <div data-testid="reset-installation-card">
              <InstallationSettingsCard
                data={{ settingName: "Reset Installation" }}
                openModalFn={() => {
                  setShowResetInstallationModal(true);
                }}
              />
            </div>
          </div>
        </>
      )}

      {currentUnitDetailsFilter === EUnitDetailsFilter.integrations && (
        <div data-testid="integration-devices">
          <IntegrationDevices
            integrationDevices={integrations}
            isInReconfigState={isInReconfigState}
          />
        </div>
      )}

      {currentUnitDetailsFilter === EUnitDetailsFilter.residents && (
        <div className="brilliant-container-residents">
          {hasIntegration && (
            <div
              data-testid="integration-link-icon"
              className="brilliant-container-integration-link center-align"
            >
              <IntegrationLink integrationName={integrationNameLink.name} />
            </div>
          )}
          <ViewResidents
            propertyId={propertyId}
            openEditResidentModal={openEditTenantModalWizard}
            setShowMoveOutModal={setShowMoveOutModal}
            setInviteTenantCurrentStep={setInviteTenantCurrentStep}
            setShowInviteTenantModal={setShowInviteTenantModal}
            setShowCancelMoveInModal={setShowCancelMoveInModal}
            setShowReconfigurationModal={setShowReconfigurationModal}
            hasIntegration={hasIntegration}
            integrationNameLink={integrationNameLink}
            isInReconfigState={isInReconfigState}
          />
        </div>
      )}
    </div>
  );
};

export default SmallBody;
