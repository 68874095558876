import {
  IBrilliantDevices,
  IIntegrationsCard,
  IIntegrationsNameAndLink,
  IUnitDetailsDataHome,
} from "../../../interfaces/interfaces";
import BackupWifiSettingsCard from "../BackupWifiSettingsCard";
import ChangesActivity from "../ChangesActivity";
import InstallationSettingsCard from "../InstallationSettingsCard";
import ViewResidents from "../ViewResidents";
import IntegrationLink from "../../common/IntegrationLink";
import {
  BrilliantDevices,
  IntegrationDevices,
} from "../common/CommonUnitDetailsComponents";

export interface LargeBodyProps {
  home: IUnitDetailsDataHome;
  brilliantDevices: IBrilliantDevices;
  shouldShowChangeActivity: boolean;
  propertyId: string;
  isInReconfigState: boolean;
  integrations: IIntegrationsCard[];
  openEditResidentModal: () => void;
  setShowMoveOutModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowBackupWifiNetworkModal: React.Dispatch<React.SetStateAction<boolean>>;
  setInviteTenantCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setShowInviteTenantModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCancelMoveInModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowReconfigurationModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowResetInstallationModal: React.Dispatch<React.SetStateAction<boolean>>;
  hasIntegration: boolean;
  integrationNameLink: IIntegrationsNameAndLink;
}

const LargeBody: React.FC<LargeBodyProps> = ({
  home,
  brilliantDevices,
  shouldShowChangeActivity,
  propertyId,
  isInReconfigState,
  integrations,
  openEditResidentModal,
  setShowMoveOutModal,
  setInviteTenantCurrentStep,
  setShowInviteTenantModal,
  setShowCancelMoveInModal,
  setShowBackupWifiNetworkModal,
  setShowReconfigurationModal,
  setShowResetInstallationModal,
  hasIntegration,
  integrationNameLink,
}) => {
  return (
    <div className="brilliant-container">
      <>
        <div className="brilliant-container-name center-align-as-row">
          <div className="menu">Residents</div>
          {hasIntegration && (
            <IntegrationLink integrationName={integrationNameLink.name} />
          )}
        </div>
        <ViewResidents
          propertyId={propertyId}
          openEditResidentModal={openEditResidentModal}
          setShowMoveOutModal={setShowMoveOutModal}
          setInviteTenantCurrentStep={setInviteTenantCurrentStep}
          setShowInviteTenantModal={setShowInviteTenantModal}
          setShowCancelMoveInModal={setShowCancelMoveInModal}
          setShowReconfigurationModal={setShowReconfigurationModal}
          hasIntegration={hasIntegration}
          integrationNameLink={integrationNameLink}
          isInReconfigState={isInReconfigState}
        />
        <br />
      </>
      {!home.isHubless && (
        <>
          <div className="brilliant-container-name menu">Brilliant</div>
          <BrilliantDevices
            brilliantControls={brilliantDevices.installedBrilliantControls}
            brilliantSwitches={brilliantDevices.installedBrilliantSwitches}
            brilliantPlugs={brilliantDevices.installedBrilliantPlugs}
            isInReconfigState={isInReconfigState}
          />
        </>
      )}
      <div>
        <div className="brilliant-container-name menu">Integrations</div>
        <IntegrationDevices
          integrationDevices={integrations}
          isInReconfigState={isInReconfigState}
        />
      </div>
      <div>
        <div className="brilliant-container-name menu">
          Installation Settings
        </div>
        <div className="brilliant-container-cards">
          <BackupWifiSettingsCard
            data={{
              networkName: home.wifiCredentials.networkName,
            }}
            openModalFn={() => {
              setShowBackupWifiNetworkModal(true);
            }}
          />
          <InstallationSettingsCard
            data={{ settingName: "Reset Installation" }}
            openModalFn={() => {
              setShowResetInstallationModal(true);
            }}
          />
        </div>
      </div>
      {shouldShowChangeActivity && (
        <ChangesActivity
          scheduledConfigurations={home.scheduledConfigurations}
        />
      )}
    </div>
  );
};

export default LargeBody;
